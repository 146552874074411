import { useState, useCallback, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"

const INITIAL_STATE = {
  name: "",
  email: "",
  phone: "",
  type: "",
  option: "",
  message: "",
}

export const useForm = () => {
  const {
    store: { locationLookupUrl },
  } = useConfigContext()
  const { callFunction } = useFunctions()
  const { forms } = useStaticQuery<GatsbyTypes.StaticFormsQuery>(graphql`
    query StaticForms {
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            handle {
              current
            }
            subject
            types {
              title
              recipients
              options
            }
          }
        }
      }
    }
  `)

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)
  const submitForm = useCallback(
    async (form, data, element) => {
      setLoading(true)
      setErrors([])

      const id = form?.id
      const type = form?.types[data?.type?.toLowerCase()]?.title.toLowerCase()
      const subject = form?.subject || `Form submission from ${form?.title}`
      const response = await fetch(locationLookupUrl)
      const userData = await response.json()

      const { status, body } = await callFunction("form-submission", {
        id,
        type,
        subject,
        data,
        ipData: userData,
        userAgent: navigator.userAgent || "",
      })

      if (status === "error") setErrors([body])

      if (status === "success") setData(INITIAL_STATE)
      if (status === "success") setSuccess(true)
      if (status === "success") element?.reset()

      setLoading(false)
    },
    [setLoading, setErrors, setData, locationLookupUrl, callFunction]
  )

  const getForm = (handle: string) =>
    forms?.edges
      ?.filter(({ node }: { node: any }) => node?.handle?.current === handle)
      .map(({ node }: { node: any }) => ({
        ...node,
        handle: node?.handle?.current,
        types: Object.assign({}, ...node.types.map((type: any) => ({ [type?.title?.toLowerCase()]: { ...type } }))),
      }))[0] || false

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  return { getForm, submitForm, data, setData, handleChange, loading, errors, success }
}

export const useFocus = () => {
  const ref = useRef(null)
  const setFocus = () => {
    //@ts-ignore next-line
    ref.current && ref.current.focus()
  }

  return [ref, setFocus]
}
