import React, { memo } from "react"
import { FormControl, FormLabel, Radio, RadioGroup, Stack } from "@chakra-ui/react"

type Props = {
  field: {
    _key: string
    _type: string
    label: string
    name: string
    placeholder?: string
    type: "text" | "email" | "tel"
    required?: boolean
    options?: string[]
  }
}

export const FormRadio: React.FC<Props> = memo(({ field }) => (
  <FormControl key={field._key} id={field?.name} isRequired={field?.required}>
    {field?.label && <FormLabel>{field?.label}</FormLabel>}
    <RadioGroup gap={2} name={field?.name}>
      <Stack direction="row">
        {field?.options?.map((option: string) => (
          <Radio key={option} value={option}>
            {option}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  </FormControl>
))
