import React from "react"
import { Alert, AlertDescription, AlertIcon, Box, Button, Collapse, Container, Select, Stack } from "@chakra-ui/react"

import { FormCheckbox } from "@app/components/Sections/Form/FormCheckbox"
import { FormInput } from "@app/components/Sections/Form/FormInput"
import { FormRadio } from "@app/components/Sections/Form/FormRadio"
import { FormSelect } from "@app/components/Sections/Form/FormSelect"
import { FormTextarea } from "@app/components/Sections/Form/FormTextarea"
import { withSection } from "@app/hoc/Section"
import { useForm } from "@app/hooks/useForm"

const fieldComponents: any = {
  text: FormInput,
  email: FormInput,
  tel: FormInput,
  textarea: FormTextarea,
  select: FormSelect,
  checkbox: FormCheckbox,
  radio: FormRadio,
}

type FieldType = {
  _key: string
  _type: string
  label: string
  name: string
  placeholder?: string
  type: "text" | "email" | "tel" | "textarea" | "select" | "checkbox" | "radio"
  required?: boolean
  options?: string[]
}

type FormHandle = {
  _type: string
  current: string
}

type Props = {
  form: {
    title: string
    handle: FormHandle
    subject: string
    Fields: FieldType[]
    types: {
      title: string
      recipients: string
      _key: string
      _type: string
    }[]
  }
}

const Form: React.FC<Props> = ({ form: { title, handle, subject, Fields, types } }) => {
  const { getForm, submitForm, loading, success } = useForm()

  const fields = Fields
  const form = getForm(handle.current)

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    // get form data from event
    const formData = new FormData(event.target as HTMLFormElement)
    // convert formData to json
    const formDataObj = Object.fromEntries(formData.entries())
    await submitForm(form, formDataObj, event.target as HTMLFormElement)
  }

  return (
    <Box paddingY={8}>
      <Container>
        <Box as="form" onSubmit={handleSubmit}>
          <input type="hidden" name="title" value={title} />
          <input type="hidden" name="subject" value={subject} />
          {types?.length > 1 ? (
            <Select name="recipients">
              {types?.map(type => (
                <option key={type?._key} value={type?.recipients}>
                  {type?.title}
                </option>
              ))}
            </Select>
          ) : (
            <input type="hidden" name="recipients" value={types?.[0]?.recipients} />
          )}
          <Stack spacing={5}>
            {fields?.map?.((field: FieldType) => {
              const fieldType = field?.type?.replace("field", "")
              const FieldComponent = fieldComponents[fieldType]
              if (!FieldComponent) return null
              return <FieldComponent key={field?._key} field={field} />
            })}
            <Button type="submit" isDisabled={loading} isLoading={loading}>
              Submit
            </Button>
          </Stack>
        </Box>
        <Collapse in={success}>
          <Alert marginTop={4} paddingY={4} status="success">
            <AlertIcon />
            <AlertDescription>Form submitted successfully!</AlertDescription>
          </Alert>
        </Collapse>
      </Container>
    </Box>
  )
}

export default React.memo(withSection(Form))
